import React, { useState } from "react";
import ModalCmp from "./../ModalCmp/index.tsx";

const ProductCarousalItem = ({ title, ItemImage }) => {
  const [isShowImage, setIsShowImage] = useState(false);
  return (
    <>
      <div className="item">
        <div className="white-box">
          <div className="product-img" onClick={() => setIsShowImage(true)}>
            <img alt="" src={ItemImage} />
          </div>
        </div>
      </div>
      <ModalCmp show={isShowImage} onHide={() => setIsShowImage(false)}>
        <div>
          <img
            style={{ height: "70vh", width: "100%", objectFit: "contain" }}
            alt=""
            src={ItemImage}
          />
        </div>
      </ModalCmp>
    </>
  );
};

export default ProductCarousalItem;
