import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import AutoParts from "../../img/company-media/category-products/Auto Parts/WhatsApp Image 2024-05-16 at 7.44.09 PM.jpeg";
import Bushes from "../../img/company-media/category-products/Bush/WhatsApp Image 2024-05-16 at 7.51.29 PM (1).jpeg";
import CastingParts from "../../img/company-media/category-products/Casting parts/WhatsApp Image 2024-05-16 at 7.45.50 PM.jpeg";
import Gears from "../../img/company-media/category-products/Gear/WhatsApp Image 2024-05-16 at 7.48.25 PM (1).jpeg";
import Shaftings from "../../img/company-media/category-products/Shafting/WhatsApp Image 2024-05-16 at 7.47.06 PM.jpeg";
import Studs from "../../img/company-media/category-products/Stud/WhatsApp Image 2024-05-16 at 7.52.40 PM (1).jpeg";
import ValveBody from "../../img/company-media/category-products/Valve Body/WhatsApp Image 2024-05-16 at 7.49.37 PM.jpeg";

const CollectionCarousal = () => {
  return (
    <OwlCarousel
      className="owl-theme"
      items={4}
      loop={true}
      margin={15}
      nav={false}
    >
      <a className="item" href="#Auto-Parts">
        <div className="item-inner-container">
          <img className="shadowed" src={AutoParts} alt="" />
          <p>Auto Parts</p>
        </div>
      </a>
      <a className="item" href="#Bush">
        <div className="item-inner-container">
          <img className="shadowed" src={Bushes} alt="" />
          <p>Bushes</p>
        </div>
      </a>
      <a className="item" href="#Casting-Parts">
        <div className="item-inner-container">
          <img className="shadowed" src={CastingParts} alt="" />
          <p>Casting Parts</p>
        </div>
      </a>
      <a className="item" href="#Gear">
        <div className="item-inner-container">
          <img className="shadowed" src={Gears} alt="" />
          <p>Gears</p>
        </div>
      </a>
      <a className="item" href="#Shafing">
        <div className="item-inner-container">
          <img className="shadowed" src={Shaftings} alt="" />
          <p>Shaftings</p>
        </div>
      </a>
      <a className="item" href="#Stud">
        <div className="item-inner-container">
          <img className="shadowed" src={Studs} alt="" />
          <p>Studs</p>
        </div>
      </a>
      <a className="item" href="#Valve-Body">
        <div className="item-inner-container">
          <img className="shadowed" src={ValveBody} alt="" />
          <p>Valve Body</p>
        </div>
      </a>
    </OwlCarousel>
  );
};

export default CollectionCarousal;
