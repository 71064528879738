import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ProductCarousalItem from "./ProductCarousalItem.tsx";

import AutoParts1 from "../../img/company-media/category-products/Auto Parts/WhatsApp Image 2024-05-16 at 7.44.09 PM.jpeg";
import AutoParts2 from "../../img/company-media/category-products/Auto Parts/WhatsApp Image 2024-05-16 at 7.44.11 PM (1).jpeg";
import AutoParts3 from "../../img/company-media/category-products/Auto Parts/WhatsApp Image 2024-05-16 at 7.44.11 PM.jpeg";

import Bushes1 from "../../img/company-media/category-products/Bush/WhatsApp Image 2024-05-16 at 7.51.29 PM (1).jpeg";
import Bushes2 from "../../img/company-media/category-products/Bush/WhatsApp Image 2024-05-16 at 7.51.29 PM.jpeg";
import Bushes3 from "../../img/company-media/category-products/Bush/WhatsApp Image 2024-05-16 at 7.51.30 PM.jpeg";

import CastingParts from "../../img/company-media/category-products/Casting parts/WhatsApp Image 2024-05-16 at 7.45.50 PM.jpeg";

import Gears1 from "../../img/company-media/category-products/Gear/WhatsApp Image 2024-05-16 at 7.48.25 PM (1).jpeg";
import Gears2 from "../../img/company-media/category-products/Gear/WhatsApp Image 2024-05-16 at 7.48.25 PM (2).jpeg";
import Gears3 from "../../img/company-media/category-products/Gear/WhatsApp Image 2024-05-16 at 7.48.25 PM.jpeg";
import Gears4 from "../../img/company-media/category-products/Gear/WhatsApp Image 2024-05-16 at 7.48.26 PM (1).jpeg";
import Gears5 from "../../img/company-media/category-products/Gear/WhatsApp Image 2024-05-16 at 7.48.26 PM.jpeg";

import Shaftings1 from "../../img/company-media/category-products/Shafting/WhatsApp Image 2024-05-16 at 7.47.06 PM.jpeg";
import Shaftings2 from "../../img/company-media/category-products/Shafting/WhatsApp Image 2024-05-16 at 7.47.07 PM (1).jpeg";
import Shaftings3 from "../../img/company-media/category-products/Shafting/WhatsApp Image 2024-05-16 at 7.47.07 PM (2).jpeg";
import Shaftings4 from "../../img/company-media/category-products/Shafting/WhatsApp Image 2024-05-16 at 7.47.07 PM.jpeg";
import Shaftings5 from "../../img/company-media/category-products/Shafting/WhatsApp Image 2024-05-16 at 7.47.08 PM.jpeg";

import Studs1 from "../../img/company-media/category-products/Stud/WhatsApp Image 2024-05-16 at 7.52.40 PM (1).jpeg";
import Studs2 from "../../img/company-media/category-products/Stud/WhatsApp Image 2024-05-16 at 7.52.40 PM (2).jpeg";
import Studs3 from "../../img/company-media/category-products/Stud/WhatsApp Image 2024-05-16 at 7.52.40 PM (3).jpeg";
import Studs4 from "../../img/company-media/category-products/Stud/WhatsApp Image 2024-05-16 at 7.52.40 PM.jpeg";

import ValveBody1 from "../../img/company-media/category-products/Valve Body/WhatsApp Image 2024-05-16 at 7.49.37 PM.jpeg";
import ValveBody2 from "../../img/company-media/category-products/Valve Body/WhatsApp Image 2024-05-16 at 7.49.38 PM (1).jpeg";
import ValveBody3 from "../../img/company-media/category-products/Valve Body/WhatsApp Image 2024-05-16 at 7.49.38 PM (2).jpeg";
import ValveBody4 from "../../img/company-media/category-products/Valve Body/WhatsApp Image 2024-05-16 at 7.49.38 PM (3).jpeg";
import ValveBody5 from "../../img/company-media/category-products/Valve Body/WhatsApp Image 2024-05-16 at 7.49.38 PM.jpeg";

interface IProps {
  productType:
    | "Auto Parts"
    | "Bush"
    | "Casting Parts"
    | "Gear"
    | "Shafing"
    | "Stud"
    | "Valve Body";
}
const ProductsCarousal = ({ productType }: IProps) => {
  console.log(productType);
  return (
    <OwlCarousel
      className="owl-theme"
      items={4}
      loop={false}
      margin={15}
      nav={false}
      dots={false}
    >
      {productType === "Auto Parts" && (
        <>
          <ProductCarousalItem title="Auto Parts" ItemImage={AutoParts1} />
          <ProductCarousalItem title="Auto Parts" ItemImage={AutoParts2} />
          <ProductCarousalItem title="Auto Parts" ItemImage={AutoParts3} />
        </>
      )}

      {productType === "Bush" && (
        <>
          <ProductCarousalItem title="Bush" ItemImage={Bushes1} />
          <ProductCarousalItem title="Bush" ItemImage={Bushes2} />
          <ProductCarousalItem title="Bush" ItemImage={Bushes3} />
        </>
      )}

      {productType === "Casting Parts" && (
        <>
          <ProductCarousalItem title="Casting Parts" ItemImage={CastingParts} />
        </>
      )}

      {productType === "Gear" && (
        <>
          <ProductCarousalItem title="Gear" ItemImage={Gears1} />
          <ProductCarousalItem title="Gear" ItemImage={Gears2} />
          <ProductCarousalItem title="Gear" ItemImage={Gears3} />
          <ProductCarousalItem title="Gear" ItemImage={Gears4} />
          <ProductCarousalItem title="Gear" ItemImage={Gears5} />
        </>
      )}

      {productType === "Shafing" && (
        <>
          <ProductCarousalItem title="Shafing" ItemImage={Shaftings1} />
          <ProductCarousalItem title="Shafing" ItemImage={Shaftings2} />
          <ProductCarousalItem title="Shafing" ItemImage={Shaftings3} />
          <ProductCarousalItem title="Shafing" ItemImage={Shaftings4} />
          <ProductCarousalItem title="Shafing" ItemImage={Shaftings5} />
        </>
      )}

      {productType === "Stud" && (
        <>
          <ProductCarousalItem title="Stud" ItemImage={Studs1} />
          <ProductCarousalItem title="Stud" ItemImage={Studs2} />
          <ProductCarousalItem title="Stud" ItemImage={Studs3} />
          <ProductCarousalItem title="Stud" ItemImage={Studs4} />
        </>
      )}

      {productType === "Valve Body" && (
        <>
          <ProductCarousalItem title="Valve Body" ItemImage={ValveBody1} />
          <ProductCarousalItem title="Valve Body" ItemImage={ValveBody2} />
          <ProductCarousalItem title="Valve Body" ItemImage={ValveBody3} />
          <ProductCarousalItem title="Valve Body" ItemImage={ValveBody4} />
          <ProductCarousalItem title="Valve Body" ItemImage={ValveBody5} />
        </>
      )}
    </OwlCarousel>
  );
};

export default ProductsCarousal;
