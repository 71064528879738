import React from "react";
import Logo from "../../img/logo.jpeg";

const HeaderWithSidebar = () => {
  return (
    <div className="tm-header tm-header-sticky" id="home">
      <div className="tm-header-middlearea">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-sm-12 order-1 order-lg-1">
              <a className="tm-header-logo nav-link" href="#home">
                <div className="logo-container">
                  <img alt="" src={Logo} className="logo-img" />
                  <div className="text-container">
                    <span className="logo-title">Anvit Components</span>
                    <span className="logo-subtitle">
                      Precision in Every Task
                    </span>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-8 col-sm-12 order-2 order-lg-3">
              <ul className="header-icons">
                <li>
                  <a className="nav-link" href="#work-collections">
                    Collections
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#about">
                    About
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#about-us">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderWithSidebar;
