import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Machinery1 from '../../img/company-media/machinery1.jpeg';
import Machinery2 from '../../img/company-media/machinery2.jpeg';
import Machinery3 from '../../img/company-media/machinery3.mp4';
import Machinery4 from '../../img/company-media/machinery4.mp4';
import Machinery5 from '../../img/company-media/machinery5.mp4';
import Machinery6 from '../../img/company-media/machinery6.mp4';
import Machinery7 from '../../img/company-media/machinery7.mp4';

const AboutCarousal = () => {
    return (
      <div className='about-carousal-container'>
        <Carousel indicators={false} interval={20000}>
          <Carousel.Item>
            <img className="d-block about-carousal-media" src={Machinery1} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block about-carousal-media" src={Machinery2} alt="Second slide" />
          </Carousel.Item>
          <Carousel.Item>
            <video autoPlay muted className="d-block about-carousal-media" src={Machinery3} />
          </Carousel.Item>
          <Carousel.Item>
            <video autoPlay muted className="d-block about-carousal-media" src={Machinery4} />
          </Carousel.Item>
          <Carousel.Item>
            <video autoPlay muted className="d-block about-carousal-media" src={Machinery5} />
          </Carousel.Item>
          <Carousel.Item>
            <video autoPlay muted className="d-block about-carousal-media" src={Machinery6} />
          </Carousel.Item>
          <Carousel.Item>
            <video autoPlay muted className="d-block about-carousal-media" src={Machinery7} />
          </Carousel.Item>
        </Carousel>
      </div>
      );
}

export default AboutCarousal