import React from "react";
import { Modal } from "react-bootstrap";

const ModalCmp = ({ children, show, onHide }) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
    >
      <button
        type="button"
        className="btn-close modal-close-btn"
        aria-label="Close"
        onClick={onHide}
      ></button>
      {children}
    </Modal>
  );
};

export default ModalCmp;
