import "./App.scss";
import "./loader.js";

import CollectionCarousal from "./components/CollectionCarousal/index.tsx";
import MainCarousal from "./components/MainCarousal/index.tsx";
import HeaderWithSidebar from "./components/HeaderWithSidebar/index.tsx";
import FooterSection from "./components/FooterSection/index.tsx";
// import ProductsSection from "./components/ProductsSection/index.tsx";

import AboutCarousal from "./components/AboutcCarousal/index.tsx";
import ProductsCarousal from "./components/ProductsCarousal/index.tsx";

function App() {
  return (
    <div className="App">
      <HeaderWithSidebar />

      <section className="main-carousal-container">
        <MainCarousal />
      </section>

      <section className="spotlight" id="about">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <h3 className="title1">Precision in every task.</h3> */}
            </div>
            <div className="spotlight-container">
              <div className="col-xl-4">
                <div className="paragraph-container">
                  For machining work, Anvit Components offers the highest degree
                  of service, drawing on vast experience in serving the
                  earth-moving machine parts and automotive industries. Our
                  machine shop and experts deliver confidence in every project.
                  We excel in the art of producing large component volumes with
                  a complete JIT (Just-In-Time) approach, maintaining accuracy
                  in machining work and ensuring long-term reliability as per
                  customer expectations.
                </div>
              </div>
              <div className="col-xl-8">
                <AboutCarousal />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="work-collections">
        <div className="container">
          <div className="home-demo">
            <h3>Our Work Collections</h3>
            <CollectionCarousal />
          </div>
        </div>
      </section>

      <section id="Auto-Parts">
        <div className="container">
          <div className="home-demo">
            <h3>Auto Parts</h3>
            <ProductsCarousal productType="Auto Parts" />
          </div>
        </div>
      </section>

      <section id="Bush">
        <div className="container">
          <div className="home-demo">
            <h3>Bush</h3>
            <ProductsCarousal productType="Bush" />
          </div>
        </div>
      </section>

      <section id="Casting-Parts">
        <div className="container">
          <div className="home-demo">
            <h3>Casting Parts</h3>
            <ProductsCarousal productType="Casting Parts" />
          </div>
        </div>
      </section>

      <section id="Gear">
        <div className="container">
          <div className="home-demo">
            <h3>Gear</h3>
            <ProductsCarousal productType="Gear" />
          </div>
        </div>
      </section>

      <section id="Shafing">
        <div className="container">
          <div className="home-demo">
            <h3>Shafing</h3>
            <ProductsCarousal productType="Shafing" />
          </div>
        </div>
      </section>

      <section id="Stud">
        <div className="container">
          <div className="home-demo">
            <h3>Stud</h3>
            <ProductsCarousal productType="Stud" />
          </div>
        </div>
      </section>

      <section id="Valve-Body">
        <div className="container">
          <div className="home-demo">
            <h3>Valve Body</h3>
            <ProductsCarousal productType="Valve Body" />
          </div>
        </div>
      </section>

      {/* <section className="product">
        <ProductsSection />
      </section> */}

      <footer>
        <FooterSection />
      </footer>

      <a className="whatapp" href="https://wa.me/917984692054">
        <i className="fa fa-whatsapp" aria-hidden="true"></i>
      </a>
    </div>
  );
}

export default App;
