import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import B1 from '../../img/company-media/banner.jpeg';
import B2 from '../../img/company-media/banner.jpeg';
import B3 from '../../img/company-media/banner.jpeg';

const MainCarousal = () => {
  return (
    <Carousel indicators={false} interval={20000}>
      <Carousel.Item>
        <img className="d-block w-100" src={B1} alt="First slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={B2} alt="Second slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={B3} alt="Third slide" />
      </Carousel.Item>
    </Carousel>
  );
};

export default MainCarousal;
